import { Component, OnInit, HostListener } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { HomeService } from 'src/app/service/home/home.service';
import { ToggleClassService } from './../../service/toggle/toggle-class.service';
import { AuthService } from 'src/app/service/auth/auth.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  public activeLang = 'pt';
  defaultLn = { lan: 'Pt', id: 'pt', flag: '/assets/img/flags/br.png' }

  languajes:any[] = [
    { lan: 'Es', id: 'es', flag: '/assets/img/flags/es.png' },
    { lan: 'Pt', id: 'pt', flag: '/assets/img/flags/br.png' },
    { lan: 'En', id: 'en', flag: '/assets/img/flags/us.png' },
  ]

  url:string = ''
  buttonActive:string = ''

  CTNredirect:boolean = false

  private subscriptions: Array<Subscription> = [];
  role:string = ''
  public constructor(
    public toggleService: ToggleClassService,
    private translate: TranslateService,
    private router: Router,
    private homeSrv: HomeService,
    private authService: AuthService,
  ) {
    let role = JSON.parse(localStorage.getItem('role'))
    this.role = role
    this.checkLanguaje()
    this.homeSrv.changeLng()
    this.subscriptions.push(
      this.router.events.subscribe((val) => {
        val instanceof NavigationEnd && ( this.url = String(this.router.url.split('/')[1]))
        this.url = String(this.router.url.split('/')[1])
      })
    )
    this.getButtonActive()
    this.listenCTNredirect()


  }


  listenCTNredirect(){
    this.authService.getCTNRedirect().subscribe((value:boolean) => {
      this.CTNredirect = value 
    })
  }


  getLang(ln:string){
    this.homeSrv.getLanguage(ln)
    .then((res:any) => {
      localStorage.setItem('language', JSON.stringify(res.data))
      this.homeSrv.changeLng()
    })
    .catch(err => {
      console.log(err)
    })
  }

  ngOnInit(): void {
   
  }
  getButtonActive(){
    this.subscriptions.push(
      this.toggleService.getButtonActive().subscribe(btnActive => {
        this.buttonActive = btnActive
      } )
    )   
  }
  checkLanguaje(){
    let languaje = localStorage.getItem('languaje') || 'pt'
    this.activeLang = languaje
    this.translate.use(languaje)
    this.defaultLn = this.languajes.find(flag => flag.id === languaje )
  }

  changeLanguaje(flag){
    localStorage.setItem('languaje', flag.id)
    this.activeLang = flag.id
    this.translate.use(flag.id)
    this.defaultLn = flag
    this.getLang(flag.id)
  }

  redirectURL(route:string){
    this.toggleService.setButtonActive(route)
    this.router.navigate([route]);
  }

  logout(){
    this.authService.doLogout();
    if(this.CTNredirect){
      window.location.href = 'https://www.cresceseunegocio.com/'
    }
    this.router.navigate(['pages/login'])
  }

}
