<nav class="w-full relative">
    <ul class="flex pt-2 px-3 md:px-6 overflow-y-auto border-b border-gris-base">
        <li class="nav-item" *ngFor="let n of menuItems" (click)="select('main', n.title);" [ngClass]="{active: buttonActive == n.path,'absolute right-2 md:right-5 flex' : n.title=='Tutorial'}">
            <div *ngIf="showLength(n.submenu) == 1">
                <div class="" *ngFor="let child of n.submenu" [ngClass]="{active: buttonActive == child.path}" (click)="select('main', child.title, $event);" >
                    <a class="botones {{child.badgeClass}}" (click)="redirect(false, child);(isMobile && showLength(n.submenu) == 1) && toggleService.comprimida = true">
                        <!-- <span class="inline-flex justify-center items-center"><span [ngClass]="[child.icon]"></span></span> -->
                        <span class="textNav">{{child.title | translate}}</span>
                    </a>
                </div>
            </div>

            <!-- ocultar cuando el link funcione -->
            <a *ngIf="n.title == 'Tutorial' && isRouteTutorial()" class="botones {{n.badgeClass}}"(click)="openCloseTutorial();"  >
                <!-- <span class="inline-flex justify-center items-center"><span [ngClass]="[n.icon]"></span></span> -->
                <span class="textNav hidden md:block">{{n.title | translate}}</span>
            </a>
            <div class="panel absolute -bottom-10" *ngIf="showLength(n.submenu) >= 2">
                <ul class="flex gap-2 my-2 justify-center">
                    <li class="nav-item" *ngFor="let child of n.submenu" [ngClass]="{active: buttonActive == child.path}" (click)="select('sub', child.title, $event);" >
                        <a class="py-1 px-2 items-center transition-colors duration-200 rounded-lg border-2 bg-white border-gris-claro-hover hover:bg-gris-claro-hover hover:bg-opacity-40 flex cursor-pointer {{child.badgeClass}}" (click)="redirect(true, child);isMobile && toggleService.comprimida = true">
                            <!-- <span class="inline-flex justify-center items-center"><span [ngClass]="[child.icon]"></span></span> -->
                            <span class="textNav">{{child.title | translate}}</span>
                        </a>
                    </li>
                </ul>
            </div>

            <!-- eliminar ultima parte del if cuando link funcione -->
            <a (click)="redirect(false, n);(isMobile && n.submenu == 0) && toggleService.comprimida = true" class="botones {{n.badgeClass}}" *ngIf="showLength(n.submenu) != 1 && n.title != 'Tutorial'">
                <!-- <span class="inline-flex justify-center items-center"><span [ngClass]="[n.icon]"></span></span> -->
                <span class="textNav">{{n.title | translate}}</span>
            </a>
            
        </li>
    </ul>
</nav>

<!-- /* original */ -->
<div *ngIf="false" class="flex bg-white navBotonera">

    <div class="flex items-center justify-center h-auto dark:bg-dark-botonera logoNav overflow-hidden">
        <div class="p-3 w-20">
            <img src="{{LOGO_PERSONALIZED}}" alt="">
        </div>
        <div *ngIf='ObjectKeys(store).length > 0' class="flex-1 w-36 md:w-32 mr-3 text-gris-base text-sm titleLogo truncate"><strong class="text-negro-principal dark:text-white text-base capitalize"> {{store.name}} </strong><br> {{store.country}} </div>
        <button class="w-14 h-14 relative transition-colors duration-200 hover:text-principal flex sm:hidden mr-1" (click)="toggleService.toggle()">
            <span class="w-6 h-0.5 bg-gris-base absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 transform rotate-45 origin-center"></span>
            <span class="w-6 h-0.5 bg-gris-base absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 transform -rotate-45 origin-center"></span>
        </button>
    </div>
    <nav class="w-full">
        <ul class="flex flex-col py-4 h-full">
            <li class="nav-item" *ngFor="let n of menuItems" (click)="select('main', n.title);" [ngClass]="{active: isActive('main', n.title)}">
                <div *ngIf="showLength(n.submenu) == 1">
                    <li class="" *ngFor="let child of n.submenu" [ngClass]="{active: isActive('main', child.title)}" (click)="select('main', child.title, $event);" >
                        <a class="botones {{child.badgeClass}}" (click)="redirect(false, child);(isMobile && showLength(n.submenu) == 1) && toggleService.comprimida = true">
                            <span class="inline-flex justify-center items-center"><span [ngClass]="[child.icon]"></span></span>
                            <span class="textNav">{{child.title | translate}}</span>
                        </a>
                    </li>
                </div>
                <!-- eliminar ultima parte del if cuando link funcione -->
                <a (click)="redirect(false, n);(isMobile && n.submenu == 0) && toggleService.comprimida = true" class="botones {{n.badgeClass}}" *ngIf="showLength(n.submenu) != 1 && n.title != 'Tutorial'">
                    <span class="inline-flex justify-center items-center"><span [ngClass]="[n.icon]"></span></span>
                    <span class="textNav">{{n.title | translate}}</span>
                </a>

                <!-- ocultar cuando el link funcione -->
                <p *ngIf="n.title == 'Tutorial'" class="botones {{n.badgeClass}}" >
                    <span class="inline-flex justify-center items-center"><span [ngClass]="[n.icon]"></span></span>
                    <span class="textNav">{{n.title | translate}}</span>
                </p>
                <div class="panel" *ngIf="showLength(n.submenu) >= 2">
                    <ul class="flex flex-col h-full">
                        <li class="nav-item" *ngFor="let child of n.submenu" [ngClass]="{active: isActive('sub', child.title)}" (click)="select('sub', child.title, $event);" >
                            <a class="botones cursor-pointer {{child.badgeClass}}" (click)="redirect(true, child);isMobile && toggleService.comprimida = true">
                                <span class="inline-flex justify-center items-center"><span [ngClass]="[child.icon]"></span></span>
                                <span class="textNav">{{child.title | translate}}</span>
                            </a>
                        </li>
                    </ul>
                </div>
            </li>
            <li *ngIf="false" class="nav-item w-full mt-5 flex flex-row items-center">
                <a target="_blank" class="pl-4 mx-0 px-2 text-sm tracking-wide text-negro-secundario  hover:text-principal focus:text-principal truncate textNav whitespace-normal" [href]="store.domain"> 
                    {{domainText}}
                </a>
                <span (click)="copyURL()" class="block cursor-pointer icon-copy pr-4 text-xl hover:text-principal focus:text-principal justify-center items-center icon-copy"></span>
            </li>
            <li *ngIf="false" class="nav-item w-full mt-5 flex items-center justify-center flex-1 cursor-pointer">
                <img [src]="store.qr" id="imgQr" class="w-48" (click)="downloadQR()"*ngIf="store.qr != ''" />
            </li>
        </ul>
    </nav>
</div>