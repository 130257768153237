import { Injectable } from '@angular/core';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class AlertsService {

  constructor() { }

  alertError(msg: string){
    Swal.fire({
      title: `${msg}`,
      icon: 'error'
    })
  }

  alertSuccess(msg:string){
    Swal.fire({
      title: `${msg}`,
      icon: 'success'
    })
  }


  alertSuccessDismiss(msg:string){
    Swal.fire({
      position: 'top-end',
      icon: 'success',
      title: `${msg}`,
      showConfirmButton: false,
      timer: 1000
    })
  }

  alertSuccessDismissTiny(msg:string){
    Swal.fire({
      position: 'top-end',
      icon: 'success',
      title: `${msg}`,
      showConfirmButton: false,
      timer: 800
    })
  }

  alertWarning(item:string){
    return Swal.fire({
      icon: 'warning',
      text: `¿Esta seguro que quiere borrar "${item}" ?`,
      showCancelButton: true,
    })
  }
  alertWarningRestore(item:string){
    return Swal.fire({
      icon: 'warning',
      text: `¿Esta seguro que quiere restaurar "${item}" ?`,
      showCancelButton: true,
    })
  }

  alertWarningText(text:string){
    return Swal.fire({
      icon: 'warning',
      title: 'Alert',
      text: `${text}`,
    })
  }

  confirmOut(){
    return Swal.fire({
      icon: 'question',
      text: 'Aún no ha guardado, ¿Seguro/a desea salir?',
      showCancelButton: true
    }).then(val => val.value ? true : false)
  }


  confirmOverWrite(info){
    let fields = ""
    Object.entries(info).map(([key, value], index) =>{
      if(value == false){
        fields += `<span class="badge badge-category badge-rounded outline-border border-2 border-transparent badge-sm mr-2 mb-2 mt-1 flex items-center">${key}</span>`;
      }
    })


    return Swal.fire({
      icon: 'question',
      title: 'Modificaciones',
      html: `
        <p class="text-sm text-gray-400 mb-2">
          Las siguientes modificaciones se aplicarán a las tiendas donde exista este producto
        </p>
        <div class="flex flex-wrap justify-center flex-row">
          ${fields}
        </div>
    `,
      showCancelButton: true
    }).then(val => val.value ? true : false)
  }

  existContact(info){
    return Swal.fire({
      icon: 'warning',
      text: `Este número esta asignado a ${info.name} ${info.lastname}`,
      showCancelButton: true,
    })
  }



}
